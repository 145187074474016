import React from "react";

export const SalaryPipedriveForm = () => {


    return (
        <div className="pipedriveWebForms"
            style={{
                width: '100%',
                height: '100vh',
            }}
            data-pd-webforms="https://webforms.pipedrive.com/f/c56JJNDT0E2XK23KBz3Zv7QCOfqTnPQcGw738xdKYqSjWYd83X9xna3z2843uCGwRd"
            id='Salary Loans (Employee Referral)'
        >

            <iframe
                src={`https://webforms.pipedrive.com/f/c56JJNDT0E2XK23KBz3Zv7QCOfqTnPQcGw738xdKYqSjWYd83X9xna3z2843uCGwRd`}
                loading="lazy"
                frameorder={"0"}
                name="Salary Loans (Employee Referral)"
                style={{
                    width: '100%',
                    height: '100vh',
                    opacity: 1,
                }}
            />

        </div>
    )

}



export const BusinessPipedriveForm = () => {
    return (
        <div className="pipedriveWebForms"
            style={{
                width: '100%',
                height: '100vh',

            }}
            data-pd-webforms="https://webforms.pipedrive.com/f/6GXSuB8furCV3SdM2Pyp5myRBNrqMCzFah39VJVPOBsJ4xSWoDgqB3YsZFxuEECzn5"
        >


            <iframe
                src={`https://webforms.pipedrive.com/f/6GXSuB8furCV3SdM2Pyp5myRBNrqMCzFah39VJVPOBsJ4xSWoDgqB3YsZFxuEECzn5`}
                loading="lazy"
                frameorder={"0"}
                style={{
                    width: '100%',
                    height: '100vh',
                    opacity: 1
                }}
            />


        </div>

    )


}


export const HardPOSPipedriveForm = () => {
    return (
        <div className="pipedriveWebForms"
            style={{
                width: '100%',
                height: '100vh',

            }}
            data-pd-webforms="https://webforms.pipedrive.com/f/6Wg08xsTzge2Cm8T5fX4O0iPzAV6k7UQiI0FXyWEnbEMsi9mT9bOwmgd78nsNdEGEH"
        >


            <iframe
                src={`https://webforms.pipedrive.com/f/6Wg08xsTzge2Cm8T5fX4O0iPzAV6k7UQiI0FXyWEnbEMsi9mT9bOwmgd78nsNdEGEH`}
                loading="lazy"
                frameorder={"0"}
                style={{
                    width: '100%',
                    height: '100vh',
                    opacity: 1
                }}
            />


        </div>

    )


}
