import React from "react"
import { BusinessPipedriveForm } from "../../../components/body/pages/general/pipedriveForm"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const SalaryPipedriveFormPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/business/salary-loan-business-form"}
      title="Salary Loan | Apply now to offer your employees a Salary Loan. | Kuda Business"
      description="Apply now to get access to Kuda Salary Loan with quick loan application, approval and disbursement for your staff.."
    />

    <BusinessPipedriveForm />
  </Layout>
)

export default SalaryPipedriveFormPage
